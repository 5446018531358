<template>
  <div id="SideMenu" class="section">
    <div style="margin-bottom: 1rem;">
      <h1 class="title is-4">BOS ADMIN</h1>
      <h1 class="subtitle is-6">BOS ADMIN</h1>
    </div>
    <b-menu>
      <b-menu-list label="Menu">
        <b-menu-item
          icon="image-area"
          label="Banner"
          tag="router-link"
          :to="{ name: 'banner' }"
          :active="
            this.$route.name === 'banner' ||
              this.$route.name === 'banner-detail'
          "
        />
        <b-menu-item
          icon="newspaper-variant"
          label="News"
          tag="router-link"
          :to="{ name: 'news' }"
          :active="
            this.$route.name === 'news' || this.$route.name === 'news-detail'
          "
        />
        <b-menu-item
          icon="shape"
          label="Category"
          tag="router-link"
          :to="{ name: 'category' }"
          :active="
            this.$route.name === 'category' ||
              this.$route.name === 'category-detail'
          "
        />
        <b-menu-item
          icon="package"
          label="Product"
          tag="router-link"
          :to="{ name: 'product' }"
          :active="
            this.$route.name === 'product' ||
              this.$route.name === 'product-detail'
          "
        />
        <b-menu-item
          icon="cart"
          label="Cart"
          tag="router-link"
          :to="{ name: 'cart' }"
          :active="
            this.$route.name === 'cart' || this.$route.name === 'cart-detail'
          "
        />
      </b-menu-list>
      <b-menu-list label="Actions">
        <b-menu-item
          icon="logout"
          label="Logout"
          @click.prevent="performLogout"
        ></b-menu-item>
      </b-menu-list>
    </b-menu>
  </div>
</template>

<script>
import Helpers from "@/utils/Helpers";

export default {
  name: "SideMenu",
  methods: {
    presentSettingDialog: function() {},
    performLogout: function() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = Helpers.performLogout();
      loadingComponent.close();
      if (response) {
        this.$router.push({ name: "login" });
      }
    }
  }
};
</script>

<style>
#SideMenu {
  text-align: left;
  height: 100vh;
}
</style>
