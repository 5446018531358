var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"NavbarMenu"}},[_c('b-navbar',{attrs:{"spaced":"","shadow":""}},[_c('template',{slot:"brand"},[_c('b-navbar-item',{attrs:{"tag":"router-link","to":{ path: '/' }}},[_c('h1',{staticClass:"title is-4"},[_vm._v("BOS ADMIN")])])],1),_c('template',{slot:"start"},[_c('b-navbar-item',{class:{
          'is-active':
            this.$route.name === 'banner' ||
            this.$route.name === 'banner-detail'
        },attrs:{"tag":"router-link","to":{ name: 'banner' }}},[_c('b-icon',{staticClass:"navbar-icon",attrs:{"icon":"image-area","size":"is-small"}}),_vm._v(" Banner ")],1),_c('b-navbar-item',{class:{
          'is-active':
            this.$route.name === 'news' || this.$route.name === 'news-detail'
        },attrs:{"tag":"router-link","to":{ name: 'news' }}},[_c('b-icon',{staticClass:"navbar-icon",attrs:{"icon":"newspaper-variant","size":"is-small"}}),_vm._v(" News ")],1),_c('b-navbar-item',{class:{
          'is-active':
            this.$route.name === 'category' ||
            this.$route.name === 'category-detail'
        },attrs:{"tag":"router-link","to":{ name: 'category' }}},[_c('b-icon',{staticClass:"navbar-icon",attrs:{"icon":"shape","size":"is-small"}}),_vm._v(" Category ")],1),_c('b-navbar-item',{class:{
          'is-active':
            this.$route.name === 'product' ||
            this.$route.name === 'product-detail'
        },attrs:{"tag":"router-link","to":{ name: 'product' }}},[_c('b-icon',{staticClass:"navbar-icon",attrs:{"icon":"package","size":"is-small"}}),_vm._v(" Product ")],1),_c('b-navbar-item',{class:{
          'is-active':
            this.$route.name === 'cart' || this.$route.name === 'cart-detail'
        },attrs:{"tag":"router-link","to":{ name: 'cart' }}},[_c('b-icon',{staticClass:"navbar-icon",attrs:{"icon":"cart","size":"is-small"}}),_vm._v(" Cart ")],1),_c('b-navbar-item',{on:{"click":function($event){$event.preventDefault();return _vm.performLogout($event)}}},[_c('b-icon',{staticClass:"navbar-icon",attrs:{"icon":"logout","size":"is-small"}}),_vm._v(" Logout ")],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }