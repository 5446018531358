<template>
  <div id="NavbarMenu">
    <b-navbar spaced shadow>
      <template slot="brand">
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <h1 class="title is-4">BOS ADMIN</h1>
        </b-navbar-item>
      </template>
      <template slot="start">
        <b-navbar-item
          tag="router-link"
          :to="{ name: 'banner' }"
          :class="{
            'is-active':
              this.$route.name === 'banner' ||
              this.$route.name === 'banner-detail'
          }"
        >
          <b-icon class="navbar-icon" icon="image-area" size="is-small" />
          Banner
        </b-navbar-item>
        <b-navbar-item
          tag="router-link"
          :to="{ name: 'news' }"
          :class="{
            'is-active':
              this.$route.name === 'news' || this.$route.name === 'news-detail'
          }"
        >
          <b-icon
            class="navbar-icon"
            icon="newspaper-variant"
            size="is-small"
          />
          News
        </b-navbar-item>
        <b-navbar-item
          tag="router-link"
          :to="{ name: 'category' }"
          :class="{
            'is-active':
              this.$route.name === 'category' ||
              this.$route.name === 'category-detail'
          }"
        >
          <b-icon class="navbar-icon" icon="shape" size="is-small" />
          Category
        </b-navbar-item>
        <b-navbar-item
          tag="router-link"
          :to="{ name: 'product' }"
          :class="{
            'is-active':
              this.$route.name === 'product' ||
              this.$route.name === 'product-detail'
          }"
        >
          <b-icon class="navbar-icon" icon="package" size="is-small" />
          Product
        </b-navbar-item>
        <b-navbar-item
          tag="router-link"
          :to="{ name: 'cart' }"
          :class="{
            'is-active':
              this.$route.name === 'cart' || this.$route.name === 'cart-detail'
          }"
        >
          <b-icon class="navbar-icon" icon="cart" size="is-small" />
          Cart
        </b-navbar-item>
        <b-navbar-item @click.prevent="performLogout">
          <b-icon class="navbar-icon" icon="logout" size="is-small" />
          Logout
        </b-navbar-item>
      </template>
    </b-navbar>
  </div>
</template>

<script>
import Helpers from "@/utils/Helpers";

export default {
  name: "NavbarMenu",
  methods: {
    presentSettingDialog: function() {},
    performLogout: function() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = Helpers.performLogout();
      loadingComponent.close();
      if (response) {
        this.$router.push({ name: "login" });
      }
    }
  }
};
</script>

<style>
#NavbarMenu {
  text-align: left;
}

.navbar-menu {
  padding: 0.5rem 1rem;
}

.navbar-icon {
  margin-right: 0.1rem !important;
}
</style>
