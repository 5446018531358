<template>
  <div class="home">
    <navbar-menu class="is-hidden-tablet" />
    <div class="columns is-multiline">
      <div
        class="column is-2-desktop is-4-tablet is-12-mobile is-hidden-mobile"
      >
        <side-menu />
      </div>
      <div class="column">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import SideMenu from "@/components/menu/SideMenu.vue";
import NavbarMenu from "@/components/menu/NavbarMenu.vue";

export default {
  name: "home",
  components: {
    SideMenu,
    NavbarMenu
  }
};
</script>
